import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Layout from "../layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../utils/font-awesome";

export const query = graphql`
  query($slug: String!) {
    blogpost: contentfulBlogPostPage(slug: { eq: $slug }) {
      author
      slug
      date
      header
      image {
        fluid(quality: 100, maxHeight: 800, maxWidth: 2400) {
          src
        }
        title
      }
      mainText {
        json
      }
      subheader
    }
  }
`;

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri}>{children}</a>;
    },
  },
};

export default function BlogPostPage({ data: { blogpost } }) {
  return (
    <Layout>
      <div className=" bg-light">
        <Container className="blogpost">
          <Link to="/Blogs" className="backIcon">
            <FontAwesomeIcon icon={["fas", "arrow-left"]} title="arrow-left" />{" "}
          </Link>
          <Row className="justify-content-sm-center">
            <Col md={6}>
              <h1 className="header">{blogpost.header}</h1>
              <div className="author">{blogpost.author}</div>
              <div className="date">
                <FontAwesomeIcon
                  icon={["far", "calendar-alt"]}
                  title="calendar-alt"
                />{" "}
                {new Date(blogpost.date).toDateString()}
              </div>
            </Col>
            <Col className="socialIconsColumn" md={6}>
              {/*   <a
                href={`https://twitter.com/intent/tweet/?text=${blogpost.slug}&url=http://www.vibildco.dk/Blogs/${blogpost.slug}%2F&via=@JohnVibild`}
                className="socialIcons"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={["fab", "twitter-square"]}
                  title="twitter-square"
                  size="2x"
                />{" "}
              </a> */}
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=http://www.vibildco.dk/Blogs/${blogpost.slug}`}
                className="socialIcons"
                target="popup"
                rel="noreferrer"
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=http://www.vibildco.dk/Blogs/${blogpost.slug}`,
                    "popup",
                    "width=600,height=600"
                  );
                  return false;
                }}
              >
                <FontAwesomeIcon
                  icon={["fab", "facebook-square"]}
                  title="facebook-square"
                  size="2x"
                />{" "}
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=http://www.vibildco.dk/Blogs/${blogpost.slug}&title=${blogpost.slug}&source=${blogpost.slug}`}
                className="socialIcons"
                rel="noreferrer"
                target="popup"
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/shareArticle?mini=true&url=http://www.vibildco.dk/Blogs/${blogpost.slug}&title=${blogpost.slug}&source=${blogpost.slug}`,
                    "popup",
                    "width=600,height=600"
                  );
                  return false;
                }}
              >
                <FontAwesomeIcon
                  icon={["fab", "linkedin"]}
                  title="linkedin"
                  size="2x"
                />{" "}
              </a>
            </Col>
          </Row>
          <main className="blog-body">
            <Row className="justify-content-sm-center">
              <Col xs={12} className="image">
                <img
                  src={blogpost.image.fluid.src}
                  alt={blogpost.image.title}
                />
              </Col>
              <Col xs={12} md={10}>
                <h6 className="subheader">{blogpost.subheader}</h6>
              </Col>
              <Col xs={12} md={10} className="blog-content__text">
                <div className="post-body">
                  {documentToReactComponents(
                    blogpost.mainText.json,
                    RICHTEXT_OPTIONS
                  )}
                </div>
              </Col>
            </Row>
          </main>
        </Container>
      </div>
    </Layout>
  );
}
